<template>
    <div class="system-container">
        <el-row>
            <el-button type="primary" size="small" @click="saveCity">保存</el-button>
        </el-row>
        <el-row class="city-transfer">
            <el-transfer
                filterable
                :filter-method="filterMethod"
                filter-placeholder="请输入城市名称"
                v-model="value"
                :titles="['未选城市', '已选城市']"
                :data="data">
            </el-transfer>
        </el-row>
    </div>
</template>

<script>
import api from "@/api/index.js";
import pinyin from "pinyin"; //汉字转拼音
export default {
    name: "layerMaintain",
    data(){
        return {
            data: [],
            value: [],
            filterMethod(query, item) {
                return item.pinyin.indexOf(query) > -1 || item.label.indexOf(query) > -1;
            },
            cusNo: ""
        };
    },
    mounted(){
        this.cusNo = sessionStorage.cusNo;
        this.initPage();
    },
    methods: {
        initPage() {
            let that = this;
            that.data = [];
            that.value = [];
            api.cusAllCityList({
                cusNo: that.cusNo
            }).then(res => {
                if(res.code == 200 && res.data != null) {
                    (res.data.allCityList || []).forEach((item, index) => {
                        let i = {
                            label: item.cnName,
                            key: item.code,
                            joinCode: item.joinCode,
                            code: item.code,
                            pinyin: pinyin(item.cnName, {
                                style: pinyin.STYLE_NORMAL // 拼音风格
                            }).join("")
                        };
                        that.data.push(i);
                    });
                    (res.data.selectCityList || []).forEach((item, index) => {
                        that.value.push(item.code);
                    });
                }
            })
        },
        saveCity() {
            let cityCode = "";
            if(this.value.length > 0) {
                cityCode = this.value.join(",");   
            }
            api.saveCusCity({
                cityCode,
                cusNo: this.cusNo
            }).then(res => {
                if(res.code == 200) {
                    this.$message({
                        type: "success",
                        message: "保存成功"
                    });
                } else {
                    this.$message.error("保存失败");
                    this.initPage();
                }
            })
        }
    },
    watch: {
    }
}
</script>
<style>
.system-container {
    font-size: 13px;
    padding: 10px 30px;
}
.city-transfer {
    margin: 20px auto;
    width: 100%;
}
.city-transfer>.el-transfer>.el-transfer-panel{
    width: 260px ;
    height: 400px;
}
.city-transfer>.el-transfer .el-transfer-panel__body .el-transfer-panel__list.is-filterable {
    height: 296px;
}
</style>
